import call from "../../service/http";
import { SetupConstants as constant } from "./index";

export default {
  namespaced: true,
  state: {
    membershipCategories: [],
    companyInformation: {},
    countries: [],
    cities: [],
    counties: [],
    postalcodes: [],
    error: "",
  },
  mutations: {
    SET_MEMBERSHIP_CATEGORIES: (state, payload) => {
      state.membershipCategories = payload;
    },
    SET_COMPANY_INFORMATION: (state, payload) => {
      state.companyInformation = payload;
    },
    SET_COUNTRIES: (state, payload) => {
      state.countries = payload;
    },
    SET_CITIES: (state, payload) => {
      state.cities = payload;
    },
    SET_POSTAL_CODES: (state, payload) => {
      state.postalcodes = payload;
    },
    SET_COUNTIES: (state, payload) => {
      state.counties = payload;
    },
    SET_ERROR: (state, payload) => {
      state.error = payload;
    },
  },
  getters: {
    membershipCategories: (state) => state.membershipCategories,
    companyInformation: (state) => state.companyInformation,
    countries: (state) => state.countries,
    cities: (state) => state.cities,
    counties: (state) => state.counties,
    postalCodes: (state) => state.postalcodes,
    error: (state) => state.error,
  },
  actions: {
    getMembershipCategories: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.membershipCategories).then((res) => {
        commit("SET_MEMBERSHIP_CATEGORIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCompanyInformation: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.companyInformation).then((res) => {
        commit("SET_COMPANY_INFORMATION", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCountries: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.countries).then((res) => {
        commit("SET_COUNTRIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCities: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.cities).then((res) => {
        commit("SET_CITIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getPostalcodes: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.postcodes).then((res) => {
        commit("SET_POSTAL_CODES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },

    getCounties: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constant.counties).then((res) => {
        commit("SET_COUNTIES", res.data.data);
        commit("SET_LOADING", false, { root: true });
      });
    },
  },
};
