<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card v-if="currEvent[0]">
          <v-card-title class="text-uppercase">
            <v-btn class="mr-3" @click="$router.back()" text>
              <v-icon left>mdi-arrow-left</v-icon>
              Back
            </v-btn>

            Event Code: {{ currEvent[0].no }} &nbsp;

            <v-chip
              dark
              :color="
                currEvent[0].status === 'Active'
                  ? 'green darken-1'
                  : 'grey darken-1'
              "
            >
              {{ currEvent[0].status }}</v-chip
            >
          </v-card-title>

          <v-divider />

          <v-row class="mt-5">
            <v-col cols="12" md="10" offset-md="1">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Event</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ currEvent[0].description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Dates</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ formatDate(currEvent[0].startDate, 6) }} -
                          {{ formatDate(currEvent[0].endDate, 6) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Location</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ currEvent[0].location }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-img />

                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Bookings</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ currEvent[0].bookings }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-btn
                      outlined
                      class="mb-1"
                      color="primary"
                      block
                      @click="getReport('eventAttandance', currEvent[0].no)"
                      >ATTENDANCE report</v-btn
                    >
                    <v-btn
                      outlined
                      class="mb-1"
                      color="primary"
                      block
                      @click="
                        getReport('dailyAttendanceReport', currEvent[0].no)
                      "
                      >ATTENDANCE PER DAY</v-btn
                    >
                    <v-btn
                      outlined
                      class="mb-1"
                      color="primary"
                      block
                      @click="
                        getReport(
                          'attendancePackageConsumption',
                          currEvent[0].no
                        )
                      "
                      >PACKAGE CONSUMPTION</v-btn
                    >
                    <v-btn
                      outlined
                      class="mb-1"
                      color="primary"
                      block
                      :to="{
                        name: 'DelegatesBio',
                        params: { event: currEvent[0].no }
                      }"
                      >Delegates</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>

              <v-data-table
                :headers="headers"
                :items="filterPackages(currEvent[0].eventPackageCost)"
                class="elevation-1 my-10"
                hide-default-footer
                :items-per-page="100"
              >
                <template v-slot:item.mandatory="{ item }">
                  {{ item.mandatory ? "Yes" : "No " }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                    :to="{
                      name: 'Delegates',
                      params: { event: item.eventNo, package: item.packageNo },
                    }"
                    dark
                    color="primary"
                  >
                    Check in
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-else>
          <v-skeleton-loader type="card-heading, table" />
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="reportDialog"
      persistent
      max-width="450"
      :disabled="$store.getters['loading']"
    >
      <v-card :loading="$store.getters['loading']">
        <v-card-title class="text-h6 text-capitalize">
          Report
          <v-spacer />
          <v-btn text @click="reportDialog = false">Close</v-btn>
        </v-card-title>

        <v-card-actions>
          <v-btn text :href="report" color="primary">
            Download
            <v-icon right>mdi-download</v-icon>
          </v-btn>

          <v-spacer />

          <v-btn
            color="secondary"
            text
            target="_blank"
            :href="`https://docs.google.com/gview?url=${report}`"
          >
            Open
            <v-icon right>mdi-link</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DateMixin from "@/modules/mixins/DateMixin";
import FileMixin from "@/modules/mixins/FileMixin";

export default {
  name: "Event",

  data: function () {
    return {
      headers: [
        {
          text: "Package Code",
          align: "start",
          sortable: false,
          value: "packageNo",
        },
        { text: "Package description", value: "description" },
        { text: "Mandatory", value: "mandatory" },
        { text: "Category", value: "membershipCategory" },
        { text: "Action", value: "action" },
      ],
      reportDialog: false,
      currEvent: [],
    };
  },

  mixins: [DateMixin, FileMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getEvents");
      v.$store.commit("Dashboard/SET_REPORT", "");
    });
  },

  computed: {
    events() {
      return this.$store.getters["Dashboard/events"];
    },

    report() {
      return this.$store.getters["Dashboard/report"];
    },
  },

  methods: {
    getReport: function (report, eventCode) {
      this.$store.dispatch("Dashboard/getReport", {
        report: report,
        eventNo: eventCode,
      });
    },
    filterPackages(packages) {
      // group packages by packageNo and select the first one
      return packages
        .reduce((acc, curr) => {
          const x = acc.find((item) => item.packageNo === curr.packageNo);
          if (!x) {
            return acc.concat([curr]);
          } else {
            return acc;
          }
        }, [])
        .map((item) => {
          return {
            ...item
          };
        });
    },
  },

  watch: {
    events: {
      handler: function () {
        this.currEvent = this.events.filter((e) => {
          return e.no === this.$route.params.code;
        });
      },
      immediate: true,
      deep: true,
    },

    report: {
      handler: function (n) {
        if (n) this.reportDialog = true;
      },
    },
  },
};
</script>

<style scoped></style>
