<template>
  <v-container class="mt-5">
    <v-card class="mx-auto">
      <v-card-title> Event {{ eventNo }} Delegates </v-card-title>
      <v-card-text>
        <v-alert
          v-if="!!alert.status"
          border="left"
          :type="alert.status"
          class="mb-5"
        >
          {{ alert.message }}
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="eventDelegates"
          class="elevation-0"
          :items-per-page="100"
        >
          <template v-slot:item.action="{ item }">
            <v-btn
              color="primary"
              class="text-capitalize"
              :disabled="item.fingerprintExists"
              @click="scan(item)"
            >
              Register Fingerprint
              <v-icon>mdi-fingerprint</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "DelegatesBio",
  data: function () {
    return {
      headers: [
        {
          text: "#No",
          align: "start",
          sortable: false,
          value: "delegateNo",
        },
        { text: "Id Number", value: "idNo" },
        { text: "Name", value: "name" },
        { text: "email", value: "email" },
        { text: "phoneNo", value: "phoneNo" },
        { text: "Action", value: "action" },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Biometric/delegates", v.eventNo);
    });
  },
  computed: {
    eventDelegates() {
      return this.$store.getters["Biometric/bioGetters"]("delegates");
    },
    eventNo() {
      return this.$route.params.event;
    },
    alert() {
      return this.$store.getters["Biometric/bioGetters"]("alert");
    },
  },
  methods: {
    scan(item) {
      this.$store.dispatch("Biometric/registerFingerprint", item);
    },
  },
};
</script>
