<template>
  <v-container class="mt-5" :fluid="fullscreen">
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title class="text-uppercase overline">
        <v-btn class="mr-3" @click="$router.back()" text>
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        {{
          event
            ? `${event.description} , ${formatDate(
                event.startDate,
                6
              )} - ${formatDate(event.endDate, 6)}, ${event.location}`
            : "" | capitalize("also this")
        }}

        <v-spacer />

        <v-btn text @click="search = !search" :color="search ? 'info' : ''">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          text
          @click="
            $store.dispatch('Dashboard/getEventDelegates', {
              eventCode: $route.params.event,
              packageCode: $route.params.package,
            })
          "
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn
          text
          @click="toggleFullscreen()"
          :color="fullscreen ? 'info' : ''"
        >
          <v-icon>{{
            fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
          }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row class="pa-5">
        <v-col cols="12" md="3">
          <v-card elevation="3" dark color="primary">
            <v-card-title class="overline">
              {{
                event
                  ? event.eventPackageCost.length > 1
                    ? event.description
                    : event.eventPackageCost[0].description
                  : ""
              }}
              Day {{ day }} of {{ event ? event.eventDurationDays : "" }}
            </v-card-title>

            <v-card-actions class="overline">
              Delegates
              <v-spacer />
              <v-chip
                dark
                color="primary lighten-5"
                text-color="primary darken-4"
              >
                {{ delegates.length }}
              </v-chip>
            </v-card-actions>

            <v-divider />

            <v-card-actions class="overline">
              Checked In
              <v-spacer />
              <v-chip
                dark
                color="primary lighten-5"
                text-color="primary darken-4"
              >
                {{ checkedIn.length }}
              </v-chip>
            </v-card-actions>
          </v-card>

          <v-card elevation="3" class="mt-5">
            <v-card-title class="overline">Settings</v-card-title>
            <v-card-text>
              <v-switch
                title="Auto Checkin on scan"
                flat
                label="Auto Checkin"
                dense
                inset
                color="primary"
                v-model="autoCheck"
                hide-details
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-card elevation="3">
            <v-card-text v-if="search" class="grey lighten-3 px-10">
              <v-btn
                @click="search = false"
                color="info"
                x-small
                text
                style="position: absolute; top: 2px; right: 2px"
                class="ma-3"
              >
                hide search
              </v-btn>

              <v-text-field
                autofocus
                label="Search delegate"
                class="mt-10"
                outlined
                placeholder="Delegate No. / Name / Email"
                append-icon="mdi-magnify"
                v-model="term"
                @focus="userInput = true"
                @blur="userInput = false"
                clearable
              />
            </v-card-text>

            <v-card-text style="min-height: 20px">
              <v-text-field
                style="position: absolute; top: -1000px"
                @keyup.enter="onScan()"
                ref="input"
                autofocus
                v-model="scanned"
              />

              <v-expand-transition>
                <v-alert
                  v-if="!!$store.getters['Dashboard/alert'].status"
                  border="left"
                  :type="$store.getters['Dashboard/alert'].status"
                  class="mb-5"
                >
                  {{ $store.getters["Dashboard/alert"].message }}
                </v-alert>
              </v-expand-transition>
            </v-card-text>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="delegates"
                :items-per-page="10"
                :fixed-header="true"
                :height="fullscreen ? '65vh' : '60vh'"
              >
                <template v-slot:item.attendance="{ item }">
                  <v-chip x-small color="secondary darken-2" text-color="white">
                    {{
                      item.delegateAttendance.filter(
                        (a) => a.packageCode === $route.params.package
                      ).length
                    }}
                    /
                    {{ item.bookingDelegatesPackage[0].units }}
                  </v-chip>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn-toggle>
                    <v-btn
                      @click="checkIn(item)"
                      icon
                      small
                      :disabled="disabledBtn(item)"
                    >
                      <v-icon color="secondary">mdi-arrow-right</v-icon>
                    </v-btn>

                    <v-btn
                      @click="getTag('qrCodeEventTag', item.delegateNo)"
                      icon
                      small
                    >
                      <v-icon color="primary">mdi-qrcode</v-icon>
                    </v-btn>

                    <v-btn @click="getFingerprint(item)" icon small>
                      <v-icon color="primary">mdi-fingerprint</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
      style="z-index: 10000000"
    >
      <v-card v-if="dialog" class="pa-5">
        <v-card-title class="text-h6 text-capitalize" v-if="!hasCheckedIn">
          Checkin {{ delegate.name | capitalize }} ?
        </v-card-title>

        <v-card-title v-else>
          <v-icon color="success">mdi-check-circle</v-icon>
          {{ delegate.name | capitalize }} has Checked In for
          {{ event.eventPackageCost[0].description }}
        </v-card-title>

        <v-card-text class="overline">
          <strong class="font-weight-black">Package</strong> :
          {{
            event.eventPackageCost.length > 1
              ? event.description
              : event.eventPackageCost[0].description
          }}
          <br />
          <strong class="font-weight-black">Units</strong>:
          {{ delegate.bookingDelegatesPackage[0].consumedUnits }}
          /
          {{ delegate.bookingDelegatesPackage[0].units }}</v-card-text
        >

        <v-card-actions v-if="!hasCheckedIn">
          <v-spacer></v-spacer>
          <v-btn large color="grey" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn large color="green darken-1" dark @click="submit">
            Checkin<v-icon right>mdi-account-check</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-actions v-else>
          <v-spacer />
          <v-btn large color="green darken-1" dark @click="dialog = false">
            Ok <v-icon right>mdi-check-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DateMixin from "@/modules/mixins/DateMixin";
import moment from "moment";
import FileMixin from "@/modules/mixins/FileMixin";
import { AuthService } from "@/modules/auth";
export default {
  name: "Delegates",
  mixins: [DateMixin, FileMixin],
  data: function () {
    return {
      autoCheck: false,
      dialog: false,
      delegate: {},
      scanned: "",
      term: "",
      prevTerm: "",
      fullscreen: false,
      search: false,
      userInput: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getEventDelegates", {
        eventCode: v.$route.params.event,
        packageCode: v.$route.params.package,
      });
      v.$store.dispatch("Dashboard/getEvents");
    });
  },

  mounted() {
    setInterval(() => {
      if (this.$refs.input && !this.userInput) this.$refs.input.focus();
    }, 1000);

    Event.$on("delegate-checked-in", () => {
      this.$store.commit("Dashboard/SET_ALERT", {
        status: "success",
        message: `${this.delegate.name} is checked in successfully`,
      });
    });

    Event.$on("checkInDelegate", (data) => {
      this.checkIn(data);
    });
  },

  computed: {
    headers() {
      return [
        {
          text: "Delegate No.",
          align: "start",
          sortable: false,
          value: "delegateNo",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Attendance", value: "attendance" },
        { text: "Action", value: "action" },
      ];
    },

    delegates() {
      return this.$store.getters["Dashboard/delegateS"]
        .filter((d) => {
          return d.bookingDelegatesPackage.find(
            (p) =>
              p.packageCode === this.$route.params.package &&
              d.eventNo === this.$route.params.event
          );
        })
        .filter((delegate) => {
          return (
            delegate.name.toLowerCase().indexOf(this.term.toLowerCase()) >= 0 ||
            delegate.memberNo.toLowerCase().indexOf(this.term.toLowerCase()) >=
              0 ||
            delegate.idNo.toLowerCase().indexOf(this.term.toLowerCase()) >= 0 ||
            delegate.email.toLowerCase().indexOf(this.term.toLowerCase()) >=
              0 ||
            delegate.phoneNo.toLowerCase().indexOf(this.term.toLowerCase()) >=
              0 ||
            delegate.delegateCompany
              .toLowerCase()
              .indexOf(this.term.toLowerCase()) >= 0 ||
            delegate.delegateNo
              .toLowerCase()
              .indexOf(this.term.toLowerCase()) >= 0
          );
        });
    },

    user() {
      return AuthService.user;
    },

    canCheckin() {
      return (
        moment() >= moment(this.event.startDate) &&
        moment() <= moment(this.event.endDate)
      );
    },

    event() {
      let events = this.$store.getters["Dashboard/events"].filter(
        (e) => e.no === this.$route.params.event
      );
      let eventsWithPackage = JSON.parse(JSON.stringify(events));

      return eventsWithPackage
        .map((e) => {
          e.eventPackageCost = e.eventPackageCost.filter(
            (p) => p.packageNo === this.$route.params.package
          );
          return e;
        })
        .shift();
    },

    day() {
      if (!this.event) return 0;

      let diff = moment().diff(moment(this.event.startDate), "days");

      if (diff < 0) return 0;

      if (diff > this.event.eventDurationDays)
        return this.event.eventDurationDays;

      return diff + 1;
    },

    hasCheckedIn() {
      if (!this.delegate.delegateAttendance) return false;

      return (
        this.delegate.delegateAttendance.filter((d) => {
          return (
            moment(d.consumedDate).diff(moment(), "days") === 0 &&
            d.packageCode === this.$route.params.package
          );
        }).length > 0
      );
    },

    checkedIn() {
      let attendance = [];
      this.delegates.forEach((d) => {
        attendance = [...attendance, ...d.delegateAttendance];
      });

      return attendance.filter((d) => {
        return (
          d.packageCode === this.$route.params.package &&
          new Date(d.consumedDate).getDate() === new Date().getDate()
        );
      });
    },

    qrTag() {
      return this.$store.getters["Dashboard/qrTag"];
    },
  },

  methods: {
    disabledBtn(item) {
      return (
        item.bookingDelegatesPackage[0].units <
        item.delegateAttendance.filter(
          (a) => a.packageCode === this.$route.params.package
        ).length
      );
    },

    filterPackage: function (delegatePackages) {
      return delegatePackages
        .filter((d) => d.packageCode === this.$route.params.package)
        .shift();
    },

    toggleFullscreen: function () {
      if (this.fullscreen) {
        document.exitFullscreen();
        this.fullscreen = false;
      } else {
        document.documentElement.requestFullscreen();
        this.fullscreen = true;
      }
    },

    onScan: function () {
      if (this.prevTerm !== "") {
        this.scanned = this.scanned.replace(this.prevTerm, "");
      }

      this.prevTerm = this.scanned.trim().replace(/['"]+/g, "");

      console.log(this.scanned.trim().replace(/['"]+/g, ""));

      let delegate = this.delegates.filter((d) => {
        return (
          d.delegateNo
            .toLowerCase()
            .indexOf(this.scanned.toLowerCase().trim().replace(/['"]+/g, "")) >= 0
        );
      });

      if (delegate.length === 1) {
        this.delegate = delegate.shift();

        if (this.hasCheckedIn) {
          this.$store.commit("Dashboard/SET_ALERT", {
            status: "info",
            message: `${this.delegate.name} has already checked in for ${this.event.eventPackageCost[0].description}`,
          });

          return 0;
        }

        if (this.autoCheck) {
          this.submit();
        } else {
          this.checkIn({ ...this.delegate });
        }
      } else {
        this.$store.commit("Dashboard/SET_ALERT", {
          status: "warning",
          message: `Delegate not found`,
        });
      }
    },

    toggle() {
      this.fullscreen = !this.fullscreen;
    },

    checkIn: function (delegate) {
      this.delegate = delegate;
      this.dialog = true;
    },

    submit: function () {
      this.$store.dispatch("Dashboard/submitAttendance", {
        eventNo: this.delegate.eventNo,
        bookingNo: this.delegate.bookingNo,
        delegateNo: this.delegate.delegateNo,
        packageCode: this.event.eventPackageCost[0].packageNo,
        consumedDate: moment().format("YYYY-MM-DD"),
        email: this.delegate.email,
        phone: this.delegate.phoneNo,
        event: this.event.description,
        startDate: this.event.startDate,
      });

      this.dialog = false;
    },

    getTag: function (report, delegateNo) {
      this.$store.dispatch("Dashboard/getQrTag", {
        report: report,
        delegateNo: delegateNo,
      });
    },

    getFingerprint: function (data) {
      this.$store.dispatch("Biometric/verifyFingerprint", data);
    },
  },

  watch: {
    qrTag: {
      handler: function (n) {
        if (n) this.downloadFile(n, "Event Ticket.pdf");
      },
    },
  },
};
</script>

<style scoped></style>
