<template>
  <v-container>
    <v-sheet min-height="70vh" rounded="lg" class="pa-5">
      <v-alert
        outlined
        v-if="$store.getters['Dashboard/alert'].status"
        dense
        border="left"
        :type="
          $store.getters['Dashboard/alert'].status === 'success'
            ? 'success'
            : 'error'
        "
        class="mb-10"
      >
        {{ $store.getters["Dashboard/alert"].message }}
      </v-alert>

      <v-data-table
        :headers="headers"
        :items="users"
        sort-by="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Users</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="50vw">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New User
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.name"
                          label=" Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          type="email"
                          v-model="editedItem.email"
                          label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          type="password"
                          v-model="editedItem.password"
                          label="Password"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-switch
                          v-model="editedItem.is_admin"
                          label="Is Admin"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this user?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="user.is_admin"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon v-if="user.is_admin" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import { AuthService } from "@/modules/auth";

export default {
  name: "Users",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Names", align: "start", sortable: false, value: "name" },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      password: "",
      is_admin: "",
    },
    defaultItem: {
      name: "",
      email: "",
      password: "",
      is_admin: "",
    },
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getUsers");
    });
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },

    users() {
      return this.$store.getters["Dashboard/users"];
    },

    user() {
      return AuthService.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("Dashboard/removeUser", { ...this.editedItem });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$store.dispatch("Dashboard/saveUser", { ...this.editedItem });
      this.close();
    },
  },
};
</script>

<style scoped></style>
