<template>
  <v-app>
    <v-app-bar app elevation="24">
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${companyInformation.logo}`"
        transition="scale-transition"
        width="50"
        @click="$route.name !== 'cert-verify' ? $router.push('/') : ''"
      />

      <div class="text-lg-h5 mx-2" v-if="$route.name === 'cert-verify'">
        {{ companyInformation.displayName }}
        <span v-if="!$vuetify.breakpoint.mobile">&ndash;</span>
        Certificate Verification
      </div>

      <v-spacer />

      <v-btn v-if="isLoggedIn" color="secondary" to="/dashboard" class="mr-3"
        >Events</v-btn
      >

      <v-btn
        v-if="$route.name !== 'Users' && isLoggedIn && user.is_admin"
        dark
        class="mr-2"
        color="blue"
        :to="{ name: 'Users' }"
        >Users</v-btn
      >

      <v-btn
        v-if="isLoggedIn"
        color="primary"
        @click="$store.dispatch('Auth/logout')"
        >Logout</v-btn
      >

      <v-btn
        v-else-if="$route.name !== 'cert-verify'"
        color="primary"
        to="/auth"
        >Login</v-btn
      >
      <v-btn v-else icon color="primary" class="mr-3" large>
        <v-icon large>mdi-certificate</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <!-- Content-->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { AuthService } from "../../modules/auth";
import SetupMixin from "@/modules/setup/SetupMixin";

export default {
  name: "LandingLayout",
  mixins: [SetupMixin],
  computed: {
    isLoggedIn() {
      return AuthService.check();
    },

    user() {
      return AuthService.user;
    },
  },
};
</script>

<style scoped></style>
