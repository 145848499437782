import Vue from "vue";
import Vuex from "vuex";
// import VuexPersistence from "vuex-persist";
import { AuthStore } from "@/modules/auth";
import { SetupStore } from "@/modules/setup";
import { DashboardStore } from "@/modules/dashboard";
import { LandingStore } from "@/modules/landing";
import BiometricStore from "../modules/dashboard/biometric/BiometricStore"

Vue.use(Vuex);

// const vuexPersist = new VuexPersistence({
//   storage: window.localStorage,
//   modules: ["Dashboard"],
// });

export default new Vuex.Store({
  state: { loading: false },

  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },
  },

  getters: {
    loading: (state) => state.loading,
  },

  modules: {
    Auth: AuthStore,
    Setup: SetupStore,
    Dashboard: DashboardStore,
    Biometric: BiometricStore,
    Landing: LandingStore,
  },

  // plugins: [vuexPersist.plugin],
});
