import call from "../../../service/http";
import constants from "./BiometricConstants";

export default {
  namespaced: true,
  state: {
    delegates: [],
    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };

      setTimeout(() => {
        state.alert = { status: "", message: "" };
      }, 10000);
    },
  },
  getters: {
    bioGetters: (state) => (val) => state[val],
  },
  actions: {
    delegates: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.delegates(data))
        .then((res) => {
          commit("MUTATE", { state: "delegates", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    registerFingerprint: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", constants.registerFingerprint(data.delegateNo))
        .then(() => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "success",
            message: "Successfully Acquired Fingerprint",
          });
          dispatch("delegates", data.eventNo);
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: err.response.data.message,
          });
          commit("SET_LOADING", false, { root: true });
        });
    },

    verifyFingerprint: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", constants.verifyDelegateFingerprint(data.delegateNo))
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          if (res.data.data.verified) {
            commit(
              "Dashboard/SET_ALERT",
              {
                status: "success",
                message:
                  "Successfully Verified Fingerpint, checking you in ....",
              },
              { root: true }
            );
            Event.$emit("checkInDelegate", data);
          }
        })
        .catch((err) => {
          commit(
            "Dashboard/SET_ALERT",
            {
              status: "error",
              message: err.response.data.message,
            },
            { root: true }
          );
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
