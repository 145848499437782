<template>
  <v-container>
    <v-sheet min-height="70vh" rounded="lg" class="pa-5">
      <v-btn-toggle color="primary" class="mb-3" dense v-model="term">
        <v-btn value=" "> All </v-btn>

        <v-btn v-for="(item, i) in eventTypes" :key="i" :value="item.code">
          <span class="hidden-sm-and-down">{{ item.code | capitalize }}</span>
        </v-btn>
      </v-btn-toggle>

      <v-data-table
        :items-per-page="15"
        :search="term"
        :headers="headers"
        :items="events"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> CPD Events </v-toolbar-title>

            <v-spacer />

            <v-text-field
              class="mt-10"
              v-model="term"
              placeholder="Search Events ..."
              dense
            >
              <template v-slot:append>
                <v-icon left>mdi-search</v-icon>
              </template>
            </v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.startDate="{ item }">
          {{ formatDate(item.startDate, 6) }}
        </template>

        <template v-slot:item.endDate="{ item }">
          {{ formatDate(item.endDate, 6) }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            :to="{ name: 'Event', params: { code: item.no } }"
            color="primary"
            tile
          >
            Select
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
import DateMixin from "@/modules/mixins/DateMixin";

export default {
  name: "Dashboard",
  mixins: [DateMixin],
  data: function () {
    return {
      term: "",
      headers: [
        {
          text: "Event name",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Type", value: "eventType" },
        { text: "Status", value: "status" },
        { text: "Location", value: "location" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Action", value: "action" },
      ],
      items: [],
    };
  },
  beforeRouteEnter: function (to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getEventTypes");
      v.$store.dispatch("Dashboard/getEvents");
    });
  },
  computed: {
    events() {
      return (
        this.$store.getters["Dashboard/events"]
          .filter((e) => {
            if (this.term === undefined) return true;

            return (
              e.eventType.toLowerCase().indexOf(this.term.toLowerCase()) > -1 ||
              e.description.toLowerCase().indexOf(this.term.toLowerCase()) >
                -1 ||
              e.location.toLowerCase().indexOf(this.term.toLowerCase()) > -1 ||
              e.startDate.indexOf(this.term) > -1
            );
          })
          .filter((e) => {
            return this.eventTypes.find((type) => type.code === e.eventType);
          }) || []
      );
    },

    eventTypes() {
      return this.$store.getters["Dashboard/eventsTypes"].filter((type) => {
        return !type.internalEvent;
      });
    },
  },
};
</script>

<style scoped></style>
