import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import confirmation from "./plugins/confirmtion";
import snack from "./plugins/snack";
import { filters } from "./utils";

Vue.config.productionTip = false;

// eslint-disable-next-line no-global-assign
Event = new Vue();

Vue.use(confirmation);
Vue.use(snack);

/* Register Filters */
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
