export default {
  events: (no) => `event${no ? "?no=" + no : ""}`,
  ems_events: (year) => `ems${year ? "/" + year : ""}`,
  sync: (code) => `ems/sync-delegates/${code}`,
  mapEmsToBC: "ems/map-ems-to-bc",
  eventTypes: "event/types",
  uploadExcel: (code) => `ems/import-delegates/${code}`,
  delegates: (code) =>
    `event/delegate?eventNo=${code.eventCode}&packageCode=${code.packageCode}`,
  attendance: "event/attendance",
  report: "event/report",
  qrTag: "event/qr-tag",
  users: "auth/users",
  saveUser: "auth/register",
  remove: "auth/user_remove",
};
