import call from "../../service/http";
import landingConstants from "./LandingConstants";

export default {
  namespaced: true,
  state: {
    certificate: null,
    verification: null,
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    certificate: (state) => state.certificate,
    verification: (state) => state.verification,
  },
  actions: {
    getCertificate: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("get", landingConstants["attendance-certificate"](payload))
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "certificate",
            value: res.data.data.shift(),
          });
        })
        .catch((err) => {
          console.error(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
    getVerification: ({ commit }, payload) => {
      commit("SET_LOADING", true, { root: true });
      call("get", landingConstants["verify-certificate"](payload))
        .then((res) => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "verification",
            value: res.data.data[0],
          });
        })
        .catch((err) => {
          console.error(err);
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};
