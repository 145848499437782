import Landing from "./Views/Landing";
import LandingLayout from "@/layouts/landing/LandingLayout";
import CertVerify from "./Views/CertVerify";
import ProviderVerify from "./Views/ProviderVerify";

export default [
  {
    path: "/",
    component: LandingLayout,
    children: [
      {
        path: "",
        component: Landing,
      },
      {
        path: "/cert-verify/:certificateNo",
        name: "cert-verify",
        component: CertVerify,
      },
      {
        path: "/provider-verify/:parameters",
        name: "provider-verify",
        component: ProviderVerify,
      }
    ],
  },
];
