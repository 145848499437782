<template>
  <v-container class="mt-5">
    <v-card>
      <v-card-title class="text-uppercase">
        <v-btn class="mr-3" @click="$router.back()" text>
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        EMS Integration

        <v-spacer />

        <v-btn small @click="downloadEvents()" dark color="primary">
          Download Events
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-card-text class="mb-0">
            <v-select
              label="Select Year"
              hide-details
              dense
              outlined
              :items="years"
              v-model="year"
              @change="getEmsEvents"
            />
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-text>
        <v-data-table
          :headers="eventHeaders"
          :items="emsEvents"
          class="elevation-0"
        >
          <template v-slot:item.action="{ item }">
            <v-btn-toggle>
              <v-btn icon outlined @click="viewApplicants(item)" x-small>
                <v-icon color="primary">mdi-view-list</v-icon>
              </v-btn>

              <v-btn
                x-small
                icon
                :disabled="!item.event_code"
                outlined
                @click="sync(item)"
              >
                <v-icon color="secondary">mdi-refresh</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent min-width="450" scrollable>
      <v-card
        :loading="$store.getters['loading']"
        :disabled="$store.getters['loading']"
      >
        <v-card-title class="text-h6 text-capitalize">
          Applicants

          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>

          <v-spacer />

          <v-btn
            :disabled="selectedEvent ? !selectedEvent.event_code : true"
            @click="participantsDialog = true"
            color="secondary"
            class="mr-2"
            small
          >
            Upload Applicants
            <v-icon right>mdi-upload</v-icon>
          </v-btn>

          <v-btn @click="mapDialog = true" color="secondary" class="mr-2" small>
            Map EMS to BC
            <v-icon right>mdi-upload</v-icon>
          </v-btn>

          <v-btn @click="downloadApplicants()" small color="primary">
            Download Applicants
            <v-icon right>mdi-download</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-2">
          <v-data-table
            fixed-header
            :headers="applicantsHeaders"
            :items="selectedEvent ? selectedEvent.applicants : []"
            :custom-filter="filterOnlyCapsText"
            :search="search"
            class="elevation-0"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="closeModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="participantsDialog"
      persistent
      max-width="450"
      :disabled="$store.getters['loading']"
    >
      <v-card :loading="$store.getters['loading']">
        <v-card-title class="text-h6 text-capitalize">
          Upload Applicants

          <v-spacer />

          <v-btn text color="green" href="/template.xlsx">
            template
            <v-icon right>mdi-file-excel</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-7">
          <v-file-input
            outlined
            label="Select Excel File"
            v-model="participantsFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="participantsDialog = false" class="mr-2"
            >Close</v-btn
          >
          <v-btn
            color="primary"
            @click="importExcel"
            :disabled="!participantsFile"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="mapDialog"
      persistent
      max-width="450"
      :disabled="$store.getters['loading']"
    >
      <v-card :loading="$store.getters['loading']">
        <v-card-title class="text-h6 text-capitalize">
          Map Ems to BC
        </v-card-title>

        <v-divider />

        <v-card-text class="mt-7">
          <v-text-field
            outlined
            dense
            label="Ems id"
            v-model="emsToBcMap.id"
            disabled
          />
          <v-text-field
            outlined
            dense
            label="BC Event Code"
            v-model="emsToBcMap.event_code"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn text @click="mapDialog = false" class="mr-2">Close</v-btn>
          <v-btn
            color="primary"
            @click="mapEmsToBC"
            :disabled="!emsToBcMap.event_code"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { helper } from "@/utils";

export default {
  name: "Ems",
  data: function () {
    return {
      search: "",
      eventHeaders: [
        {
          text: "Event Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Name", value: "name" },
        { text: "Nature", value: "nature" },
        { text: "PDU", value: "pdu" },
        { text: "Category", value: "category" },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "Action", value: "action" },
      ],
      applicantsHeaders: [
        {
          text: "Registration No.",
          align: "start",
          sortable: false,
          value: "reg_number",
        },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "email", value: "email" },
        { text: "Category", value: "category" },
        { text: "Ticket No.", value: "ticket_number" },
        { text: "Attendance Nature", value: "attendance_nature" },
        { text: "Date Paid", value: "date_paid" },
      ],
      emsToBcMap: {
        id: "",
        event_code: "",
      },
      selectedEvent: null,
      dialog: false,
      mapDialog: false,
      participantsDialog: false,
      year: new Date().getFullYear(),
      participantsFile: null,
    };
  },

  mounted() {
    Event.$on("excel-uploaded", () => {
      this.participantsDialog = false;
      this.mapDialog = false;
    });

    Event.$on("ems-to-bc-mapped", () => {
      this.mapDialog = false;
      this.participantsDialog = false;
    });
  },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getEmsEvents();
    });
  },

  computed: {
    emsEvents() {
      let events = this.$store.getters["Dashboard/emsEvents"];

      if (this.selectedEvent && events.length > 0)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedEvent = events
          .filter((v) => v.id === this.selectedEvent.id)
          .shift();

      return events;
    },

    years() {
      let years = [];
      let currYear = moment().year();

      for (let i = currYear - 5; i <= currYear; i++) {
        years.push(i);
      }
      return years;
    },
  },

  methods: {
    getEmsEvents: function () {
      this.$store.dispatch(
        "Dashboard/getEmsEvents",
        this.year || new Date().getFullYear()
      );
    },

    viewApplicants: function (event) {
      this.selectedEvent = event;
      this.emsToBcMap.id = event.id;
      this.dialog = true;
    },

    closeModal: function () {
      this.dialog = false;
      this.selectedEvent = null;
    },

    downloadEvents: function () {
      let csvData = this.emsEvents.map((datum) => {
        return {
          id: datum.id,
          name: datum.name,
          nature: datum.nature,
          pdu: datum.pdu,
          category: datum.category,
          start_date: datum.start_date,
          start_time: datum.start_time,
          end_date: datum.end_date,
          end_time: datum.end_time,
        };
      });
      this.csvExport(csvData, `EMS Events`);
    },

    downloadApplicants: function () {
      let csvData = this.selectedEvent.applicants.map((datum) => {
        return {
          reg_number: datum.reg_number,
          name: datum.name.replaceAll(",", ""),
          phone: datum.phone + "",
          email: datum.email,
          category: datum.category,
          ticket_number: datum.ticket_number,
          attendance_nature: datum.attendance_nature,
          date_paid: datum.date_paid,
        };
      });
      this.csvExport(csvData, `${this.selectedEvent.id} Applicants`);
    },

    csvExport: function (csvData, filename) {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += [
        Object.keys(csvData[0]).join(","),
        ...csvData.map((item) => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const csv = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", csv);
      link.setAttribute("download", `${filename || "file"}.csv`);
      link.click();
    },

    importExcel: function () {
      this.$store.dispatch("Dashboard/uploadExcel", {
        data: helper.prepareFormData({ uploaded_file: this.participantsFile }),
        id: this.selectedEvent.event_id,
      });
    },

    mapEmsToBC: function () {
      this.$store.dispatch("Dashboard/mapEmsToBC", this.emsToBcMap);
    },

    sync: function (event) {
      this.$store.dispatch("Dashboard/sycToBC", event.event_code);
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>

<style scoped></style>
