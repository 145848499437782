<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-img
        alt="Logo"
        class="shrink mr-2"
        contain
        :src="`data:image/png;base64,${companyInformation.logo}`"
        transition="scale-transition"
        width="50"
        @click="$router.push('/')"
      />

      <v-spacer />

      <v-btn
        v-if="$route.name !== 'Ems' && hasEms"
        dark
        text
        class="mr-2"
        color="info"
        :to="{ name: 'Ems' }"
        >EMS Events</v-btn
      >

      <v-btn
        v-if="$route.name !== 'Dashboard'"
        dark
        class="mr-2"
        color="secondary"
        :to="{ name: 'Dashboard' }"
        >Events</v-btn
      >

      <v-btn
        v-if="$route.name !== 'Users' && user.default"
        dark
        class="mr-2"
        color="blue"
        :to="{ name: 'Users' }"
        >Users</v-btn
      >

      <v-btn color="primary" @click="$store.dispatch('Auth/logout')"
        >Logout</v-btn
      >
    </v-app-bar>

    <v-main class="grey lighten-3">
      <router-view />
    </v-main>

    <spinner />

    <confirmation />

    <snack-bar />

    <Footer />
  </v-app>
</template>

<script>
import { appName, hasEms } from "@/environment/environment";
import Spinner from "@/plugins/loader/views/Spinner";
import Confirmation from "@/plugins/confirmtion/Confirmation";
import Footer from "@/components/Footer";
import SetupMixin from "@/modules/setup/SetupMixin";
import { AuthService } from "@/modules/auth";
import SnackBar from "@/plugins/snack/SnackBar";
import { DashboardService } from "@/modules/dashboard";

export default {
  name: "DashboardLayout",

  mixins: [SetupMixin],

  components: { SnackBar, Footer, Confirmation, Spinner },

  data: function () {
    return {
      loading: false,
    };
  },

  created() {
    DashboardService.subscribe();
    this.$store.commit("Dashboard/SET_ALERT", null);
  },

  computed: {
    appName() {
      return appName;
    },

    user() {
      return AuthService.user;
    },

    hasEms() {
      return hasEms === "true";
    },
  },
};
</script>

<style scoped></style>
