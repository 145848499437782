export default {
  "attendance-certificate": (data) =>
    `event/attendance-certificate?${getParams(data)}`,
  "verify-certificate": (data) => `event/verify-certificate?${getParams(data)}`,
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
