import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Dashboard from "./components/Dashboard";
import Auth from "../../router/middleware/Auth";
import Users from "@/modules/dashboard/components/Users";
import Event from "@/modules/dashboard/components/Event";
import Delegates from "@/modules/dashboard/components/Delegates";
import DelegatesBio from "./biometric/DelegatesBio";
import Ems from "@/modules/dashboard/components/Ems";

export default [
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: { middleware: [Auth], breadcrumb: "Dashboard" },
      },

      {
        path: "users",
        name: "Users",
        component: Users,
        meta: { middleware: [Auth], breadcrumb: "Users" },
      },

      {
        path: "ems",
        name: "Ems",
        component: Ems,
        meta: { middleware: [Auth], breadcrumb: "Ems Integration" },
      },

      {
        path: "event/:code",
        name: "Event",
        component: Event,
        meta: { middleware: [Auth], breadcrumb: "Event" },
      },
      {
        path: "delegates/:event/:package",
        name: "Delegates",
        component: Delegates,
        meta: { middleware: [Auth], breadcrumb: "Event" },
      },
      {
        path: "delegatesbio/:event",
        name: "DelegatesBio",
        component: DelegatesBio,
        meta: { middleware: [Auth], breadcrumb: "Event" },
      },
    ],
  },
];
